<template lang="pug">
    #ImageGrid
        ul
            li(v-for="item, index in items")
                .img
                    img(:src="item.image").image
                    p.description {{ item.description }}
</template>
 
<script>
import { props } from '@/mixins/component'

export default {
    name: "component-image-grid",
    props,
    data() {
        return {
            items: [],
        }
    },
    mounted() {
		this.items = this.contents.content.map(item => ({
			...item,
			image: `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${item.image}`
		}))
	}
}
</script>

<style lang="stylus" scoped src="./ImageGrid.styl"></style>
